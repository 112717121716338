/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.MenuOverlay{
&-SearchLinkWrapper{
  align-items: center;
  display: grid;
  padding-left: 30px;
  border-bottom: 1px solid #e6e6e6;
  height: 50px;
  text-transform: capitalize;
    font-size: 21px !important;
    font-weight: bold;
}

}

.Menu-ItemList_type_subcategory{
  margin-top: 0 !important;
}
.Field_name_SearchField{
  z-index: 25;
  width: 100%;
  margin-bottom: 2rem;
  input{
    width: 100%;
  }
}
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.ProductLinks {
  margin: 2rem 5rem;
  @include mobile {
    margin-top: 1.4rem;
    border-bottom: 1px solid var(--primary-divider-color);
  }

  @include desktop {
    grid-column: 1 / span 2;
  }

  &-Wrapper {
    padding-bottom: 0;
    padding-top: 0;
  }

  &-List {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include mobile {
      grid-gap: 1.4rem;
    }

    @include after-mobile {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px;
      justify-content: center;
    }

    @include tablet {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &-Title {
    text-transform: none;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 4rem;
    @include mobile {
      font-size: 2.5rem !important;
    }
    &::after {
      content: '';
      display: block;
      margin: 0 auto;
      max-width: 5%;
      padding-top: 20px;
      border-bottom: 1rem solid #e83508;
    }
  }
  .ProductCard {
    &-Figure {
      padding: 3rem;
      background: $light-gray;
    }
  }
}

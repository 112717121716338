/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.MenuCollapsable-ExpandableContentContent {
  display: none;
  &_isContentExpanded {
    display: block;
  }
}
@include desktop {
  li.Menu {

    &-ItemCaption_type_main {
      border-bottom: 5px solid transparent;
      padding-top: 5px;
    }
    &-Link_isHovered {
      .Menu-ItemCaption_type_main {
        border-bottom: 3px solid $default-base-orange-color;
      }
    }
    &-SubCategories {
      max-width: 1400px;
      padding: 40px 25px 60px 25px;

      .Menu-ItemList {
        margin: 0 auto;

        // display: grid;
        // grid-template-columns: repeat(6, 1fr);
        // grid-gap: 0 50px;
        .Menu-SubLevelDesktop {
          .Menu-ItemList {
            display: grid;
            grid-template-columns: 1fr;
          }
        }
      }
    }
    &-SubItemWrapper {
      flex-basis: unset;
      margin: 0;
      .Menu-Link {
        font-family: $font-sofia-pro-black;
        font-size: 16px;
        color: $default-primary-base-color;
        text-transform: capitalize;
        &:hover {
          color: $default-base-orange-color;
        }

      }
    }
    &-SubLevelDesktop {
      .Menu-Link {
        margin-top: 0;
        font-family: $font-open-sans-semibold;
        display: flex;
        &:hover {
          color: $default-base-orange-color;
        }
        &::before {
          content: '\\';
          padding-right: 5px;
        }
      }
    }

    &-Item {
      font-family: "open sans semi bold";
      padding: 0 1rem;

      @media only screen and (max-width: 1100px) {
        padding: 0 0.55rem;
      }
    }
  }
}

@include mobile {
  .Menu-Promotion {
    display: none;
  }
}

@font-face {
  font-family: 'open sans light';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../fonts/OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'open sans regular';
  font-weight: normal;
  src: local(''), url('../fonts/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'open sans semi bold';
  font-weight: 600;
  src: local(''), url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'open sans bold';
  font-weight: bold;
  src: local(''), url('../fonts/OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'open sans extra bold';
  font-weight: bolder;
  src: local(''), url('../fonts/OpenSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'sofia pro black';
  font-style: normal;
  font-weight: bold;
  src: local(''),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../fonts/Sofia-Pro-Black.otf')
      format('OpenType');
}

:root {
    --featured-blog-text-color: #FFFFFF;
    --featured-blog-text-color-no-bg: #07182C;
}

.CmsPage-Content {
    .Viden-Page,
    .front-page {
        padding-bottom: 2.16rem;

        .post-header {
            // padding-top: 190px !important;
            // margin-top: 75px !important;
        }
    }

    .FeaturedBlogContainerTop.No-Bg {
        background: none !important;

        .YdelserTitle,
        .FeaturedTitle {
            color: $default-primary-dark-color !important;
        }
    }

    .VidenContainer,
    .FeaturedBlogContainerTop {
        background: $default-primary-dark-color;

        .RenderWhenVisible {
            @include mobile {
                padding-bottom: 25px;
            }
        }

        .FeaturedBlogContainer {
            max-width: 1100px;
            margin-left: auto;
            margin-right: auto;

            @include desktop {
                display: grid;
                grid-template-columns: .2fr 1fr;
                grid-template-areas:
                    'SectionTitle MainTitle'
                    'Grid Grid';
            }

            .RenderWhenVisible {
                @include mobile {
                    padding-bottom: 90px;
                }
            }

            .post-item-link {
                color: var(--featured-blog-text-color);
                font-weight: bold;
            }

            .post-description {
                p {
                    color: var(--featured-blog-text-color);
                }

                .post-read-more {
                    color: var(--featured-blog-text-color);
                    font-weight: bold;
                }
            }
        }

        &.No-Bg {
            .FeaturedBlogContainer {
                .post-item-link {
                    color: var(--link-color);
                    font-weight: bold;
                }

                .post-description {
                    p {
                        color: var(--featured-blog-text-color-no-bg);
                    }

                    .post-read-more {
                        color: var(--link-color);
                        font-weight: bold;
                    }
                }
            }
        }

        .YdelserSectionTitle,
        .FeaturedSectionTitle {
            color: $default-base-orange-color;
            font-size: 1.8rem;
            text-transform: uppercase;
            grid-area: SectionTitle;
            margin-top: 1rem;
            @include mobile {
                margin-top: 2.52rem;
                padding-top: .7rem;
                padding-left: 0;
            }
        }

        .YdelserTitle,
        .FeaturedTitle {
            color: $white;
            grid-area: MainTitle;
            font-size: 4em;
            text-align: center;
            text-transform: none;
            margin-top: 0;
            margin-bottom: 50px;
            max-width: 80%;
            line-height: 55px;

            @include mobile {
                text-align: left;
                padding: 0;
                font-size: 2rem;
                margin-bottom: 2rem;
            }
        }

        .post-list-wrapper {
            grid-area: Grid;
            color: $default-primary-dark-color;

            .post-list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 50px 25px;

                @include mobile {
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 50px 25px;
                }
                @media only screen and (max-width: 400px) {
                    grid-template-columns: 1fr;
                }
            }

            // .title {
            //   display: none;
            // }
            .post-holder {
                padding: 0;

                @include mobile {
                    width: 100%;
                }

                .post-ftimg-hld {
                    // transform: scale(1.0019);
                    background: #07182c;
                    display: block;
                    overflow: hidden;
                    position: relative;
                    background: #07182c;

                    .Image {
                        display: block;
                        transition: 0.3s ease-in-out;

                        .Image-Image {
                            display: block;
                        }
                    }
                }

                &::before {
                    content: none;
                }

                .post-categories {
                    display: none;
                }

                &:hover {
                    .post-ftimg-hld .Image {
                        transform: scale(1.05);
                    }
                }
            }

            // .post-title {
            //   width: 100%;
            //   .post-item-link {
            //     display: block;
            //   }
            //   a {
            //     color: white;
            //   }
            // }
            // .post-info {
            //   width: 100%;
            // }
            // .post-text-hld {
            //   overflow: hidden;
            //   div {
            //     display: none;
            //   }
            // }
            // .Blog-Title-Description {
            //   padding: 2rem 0;
            //   display: -webkit-box !important;
            //   -webkit-line-clamp: 3;
            //   -webkit-box-orient: horizontal;
            //   overflow: hidden;
            // }

            // .post-read-more {

            //   text-decoration: none;
            //   max-width: 180px;
            //   &:hover {

            //     background: $default-base-orange-color;

            //   }

            //   color: $white;
            //   padding: 1rem 2rem;
            //   text-align: center;
            //   border: 2px solid $default-base-orange-color;
            //   transition: 0.2s ease-in-out;

            // }

            .post-description {
                // display: grid;
                // grid-template-columns: 1fr;
                // position: relative;
                // @include mobile {
                //   width: 100%;
                // }

                // .post-ftimg-hld {
                //   width: 100%;
                //   .Image {
                //     @include desktop {
                //       padding-bottom: 50%;
                //     }
                //     @media only screen and (max-width: 1024px) {
                //       padding-bottom: 45%;
                //     }
                //     @media only screen and (max-width: 800px) {
                //       padding-bottom: 55%;
                //     }

                //     @media only screen and (max-width: 600px) {
                //       padding-bottom: 60%;
                //     }
                //     @media only screen and (max-width: 400px) {
                //       padding-bottom: 45%;
                //     }

                //     height: 0;
                //     position: relative;
                //   }
                //   .Image-Image {
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //   }
                // }
                .post-text-hld {
                    // overflow: hidden;
                    // margin-top: 30px;
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        display: none;
                    }
                    // p {
                    //   display: -webkit-box;
                    //   -webkit-line-clamp: 3;
                    //   -webkit-box-orient: vertical;
                    //   overflow: hidden;
                    //   @include mobile {
                    //     font-size: 1.8rem;
                    //   }
                    // }
                }
            }
        }
    }
}

.post-list-slider {
    .post-text-hld {
        @media only screen and (max-width: 450px) and (min-width: 200px) {
            margin-top: 0 !important;
        }
    }
}

.slick-track {
    display: flex;

    .slick-slide {
        height: inherit;

        > div {
            height: 100%;
        }
    }
}

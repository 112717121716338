/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.Footer {
  background: $light-gray;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 3px solid $default-primary-dark-color;
  padding: 70px 0;

  @include mobile {
    padding: 0;
  }
  .Image {
    padding: 0 0 25px 0;
    height: auto;
    display: none;

    .Image-Image {
      position: relative;
      height: auto !important;
      object-fit: contain;
      display: block;
    }

    &:not(.width-control) {
      @include mobile {
        margin-right: auto;
      }
    }
  }
  &-Content {
    background: none;
    max-width: 1100px;
    margin: 0 auto;
    .Footer-Block {
      color: $white;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        'logo .. ..'
        'left .. right';

      @include tablet {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'logo  ..'
          'left  left'
          'right right';
      }
      @include mobile {
        grid-template-columns: 1fr;
        grid-template-areas:
          'logo'
          'left'
          'right';
        padding: 25px;
        text-align: center;
      }

      &-Company-Logo {
        display: grid;
        grid-area: logo;
        width: 125px;
        @include mobile {
          .Image {
            // margin: 0 auto;
          }
        }
      }


      &-Left {
        display: grid;
        grid-area: left;
        grid-template-columns: 1fr 1fr;
        @include desktop {
          .Footer-Column {
            &:first-of-type {
              .Footer-ColumnTitle {
                margin-bottom: 0;
              }
              .Footer-Column-Content {
                grid-gap: 0 !important;
              }
            }
          }
        }

        // @media (min-width: 1024px) {
        //   .Footer-Column:last-of-type {
        //     width: 38%;
        //   }
        // }

        .Footer-Column{
          @include mobile{
          grid-template-rows: 1fr .5fr 1fr;
          &:first-of-type{
            margin-bottom: 0 !important;
          }
        }
        }
      }
      &-Right {
        display: grid;
        grid-area: right;
        grid-template-columns: 1fr 1fr;
        @include desktop {
          margin-left: auto;
        }

        // grid-gap: 60px;

        @include mobile {
          // margin-left: 0;
          grid-gap: 0px;
        }
      }

      .Footer-Column {
        display: grid;
        grid-template-columns: 1fr;
        padding-right: 0 !important;
        max-width: 100% !important;
        text-align: left;


        &-Content {
          line-height: 15px;
          @include mobile{
          margin-bottom: 10px;
        }
        }
        &Title {
          color: $default-primary-dark-color;
          font-size: 16px;
          font-weight: bold;
          text-transform: none;
          margin-bottom: 5px !important;
        }
        ul {
          display: grid;
        }
        li {
          padding: 0 0;
          font-size: 16px;
          line-height: 1.5;
            margin-bottom: 0 !important;
          color: $default-primary-base-color;
        }
        @include desktop{
          .Open-Hours-Li{
            margin-top: 10px;
          }
        }

        li::before {
          content: none;
        }
        a {
          width: 100%;
        }
      }
    }
  }
  &-Copyright-Container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 30px 0;
    border-top: 1px solid $default-primary-dark-color;
    color: $default-primary-base-color;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'left .. right';
    @include mobile {
      display: block;
    }
    p {
      padding: 0;
      font-size: 14px;
    }
    a {
      color: $default-primary-base-color;
    }
    .Footer-Copyright-Left {
      display: grid;
      grid-area: left;
      @include mobile {
        text-align: center;

      }
    }
    .Footer-Copyright-Right {
      display: grid;
      grid-area: right;
      text-align: right;
      @include mobile {
        text-align: center;
      }
    }
  }
}

.HamagProfil-Colored {
  @include mobile {
    background: $default-primary-dark-color;
  }
  .Iframe-Wrapper {
    @include desktop {
      // margin-bottom: -180px;
    }
  }
}

.Om-HamagProfil-Body {
  // background: $default-primary-dark-color;
}

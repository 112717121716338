/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';


.ProductActions-AddToCart{
    font-size: 16px !important;
}
.AddToCart-CartIcon{
    padding: 11px ;
    vertical-align: middle;
    background: $default-base-orange-color;
    @include desktop{
        height: 50px;
        width: 45px;
    }
    @include mobile{
        height: 50px;
        width: 50px;
    }
}

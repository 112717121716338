/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountDocuments {
    @include mobile {
        grid-gap: 2.8rem;
    }

    &-Button {
        @include after-mobile {
            position: absolute;
            top: -55px;
            right: 0;
        }
    }

    &-Table:not(.MyDownloadable) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .MyDownloadable {
        @include mobile {
            white-space: nowrap;
        }
    }

    td,
    th {
        @include mobile {
            min-width: 0;
        }
    }
}

.MyDownloadableOrders {
    @include mobile {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        width: calc(100vw - var(--my-account-wrapper-padding-mobile) * 2);
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme-abstract';

.category-scroll {
  .root {
    margin-right: 3rem;
    min-width: 20rem;

    .cate_name {
      font-size: 1.6rem;
    }
  }
}

.HamagProfil {
  display: grid;
  grid-template-columns: 1fr;

  &-Img {
    @include desktop {
      min-height: 500px;
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    @include mobile {
      display: none;
    }
  }

  &-Body {
    background-image: url(images/Footer-World-In-Dots.png),
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 45%,
        $default-primary-dark-color 20%
      );
    background-repeat: no-repeat;
    background-size: cover;
    height: calc(100% - 3rem);
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin: 0 auto;

    @include mobile {
      height: auto;
      background-size: cover;
    }
  }
  &-Header {
    // padding: 3rem 2rem;
max-width: 1100px;
margin: 0 auto;
    padding-top: 75px;
    color: $white;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    text-align: center;
    display: block;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include mobile {
      padding-top: 2.52rem;
      grid-gap: 1rem;
    }
    h2 {
      font-size: 4rem;
      margin-bottom: 1rem!important;
      text-transform: none;
      margin: 0;
      @include mobile {
        font-size: 3rem !important;
      }
    }
    p {
      
      max-width: 65%;
      margin: 0 auto;
      padding-bottom: 20px;
      @include desktop{
        font-size: 1.8rem;
      }
      @include mobile {
        font-size: 1.4rem;
        max-width: 100%;
      }
    }
  }

  .outer-iframe-wrapper {
    max-width: 1100px;
    margin: 0 auto;
    width: 90%;
    margin-top: 3rem;

    @include mobile {
      margin-top: 0;
      width: 100%;
    }

  }

  .Iframe-Wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
  }
  #ytplayer {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &-Content {
    max-width: 1100px;
    margin: -135px auto 0 auto;
    margin-top: 0;
    @include mobile {
      margin: 2rem;
    }
    color: $default-primary-dark-color;
    @include mobile {
      max-width: 100%;
    }
  }
  &-Text {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    h3 {
      line-height: 55px;
      @include desktop{
        font-size: 50px !important;
      }
      @include mobile{
        font-size: 28px !important;
      }
    }
    h4 {
      font-size: 2rem !important;
      margin-top: 2rem;
    }
    p {
      font-size: 1.8rem;
    }
  }
}

.Products-Container {
  // margin-top: 100px;
  // margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5rem;
  color: #07182c;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 75px 0;
  @include mobile {
    padding: 0 2rem;
    grid-gap: 2rem;
  }
}
.Products {
  &-Header {
    display: grid;
    grid-template-columns: 0.1fr 1fr;
    grid-template-areas:
      'leftTitle mainTitle'
      'subtitle subtitle';
    @include tablet {
      grid-template-columns: 1fr;
      grid-template-areas:
        'leftTitle '
        'mainTitle'
        'subtitle ';
    }
    @include mobile {
      grid-template-columns: 1fr;
      grid-template-areas:
        'leftTitle '
        'mainTitle'
        'subtitle ';
    }

    h3 {
      grid-area: leftTitle;
      text-transform: uppercase;
      color: $default-base-orange-color;
      padding-top: 1rem;
      // margin-top: 0 !important;
    }
    h2 {
      // margin-top: 0 !important;
      grid-area: mainTitle;
      font-size: 50px;
      text-align: center;
      font-weight: 700;
      max-width: 80%;
      margin-left: 60px;
      text-transform: none;
      line-height: 55px;
      margin-bottom: 50px;

      @include mobile {
        max-width: 100%;
        font-size: 3rem !important;
        margin-left: 0;
        text-align: left;
        margin-top: 0;
        margin-bottom: 25px;
      }
    }
    &-Subtitles {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-area: subtitle;
      grid-gap: 15px;

      @include mobile {
        grid-template-columns: 1fr;
      }
    }
    .Subtitles-Column {
      display: grid;
      grid-template-columns: 0.2fr 6fr;
      grid-template-rows: 0.3fr 1fr;
      grid-template-areas:
        'dash title'
        '.. text';
    }
    .Slash {
      @include slash(dash, 2rem, 1.5rem, 0.8rem);
      top: -0.5rem;
    }
    .Title {
      grid-area: title;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 12px;
    }
    .Text {
      grid-area: text;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &-List {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px 30px;
    @include tablet {
      grid-template-columns: 1fr 1fr;
    }
    @include mobile {
      grid-template-columns: 1fr 1fr;
    }
    &-Card {
      overflow: hidden;
      transition: 0.2s ease-in-out;
      position: relative;
      padding-left: 0;
      &::before {
        content: '';
      }
      &:hover {
        transform: scale(1.05);
        .title,
        .text {
          text-decoration: none !important;
        }
      }
      &-Link {
        z-index: 10;
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
      }
      .Image {
        position: relative;
      }
      img {
        width: 100%;
      }
      .title {
        padding-top: 2.5rem;
        color: $default-primary-dark-color;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        @include mobile {
          font-size: 16px;
        }
      }
      .text {
        font-size: 16px;
        @include mobile {
          font-size: 1.4rem;
        }
      }
    }
  }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.Loader {
  background-color: unset;
}

.LoaderContainer {
  min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
}

// override default padding on li

.carousel-root {
  li {
    &:before {
      display: none;
    }
  }

  .carousel.carousel-slider {
    .slide.selected {
      padding-left: 0;
    }

    .control-dots .dot {
      padding: 0;
    }
  }
}

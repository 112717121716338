.CmsPage {
    .Image {
        padding: 0;
        height: auto;
        display: block;

        .Image-Image {
            position: relative;
            height: auto !important;
            object-fit: unset;
            display: block;
        }

        &:not(.width-control) {
            width: 100% !important;
        }
    }
}
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.Breadcrumb {
  padding: 0 5px 0 0;
  &:not(:last-child) {
    &::after {
      content: '\\';
      position: relative;
      display: inline-block;
      color: white;
      height: 4px;
      width: 4px;
      transform: rotate(0deg);
      margin-left: 5px;
      border-color: currentColor;
      border-style: solid;
      border-width: 0 0 0 0;
      top: 0px;
    }
  }

  &:last-child {
    --breadcrumbs-color: var(--breadcrumbs-active-color);

    pointer-events: none;
    margin: 0;
  }
}

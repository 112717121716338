.Cover {
    margin-top: 20px;

    &-Image-Container {
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 600px;
        position: relative;
        color: $white;
        background-position: center;

        @include mobile {
            padding-bottom: 500px;
        }
    }

    &-Title-Container {
        height: 100%;
        background: rgba(0, 0, 0, .267);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        @include mobile {
            display: none;
        }
    }

    &-Title {
        position: absolute;
        bottom: 170px;
        left: 215px;
        font-size: 100px;
        line-height: 1;
        width: 75%;
        text-transform: none;
    }

    &-TextBox {
        padding: 35px 0 35px 128px;
        width: 857px;

        background: $default-primary-dark-color;
        position: absolute;
        bottom: -15%;
        left: 0;
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 11fr;
        grid-template-areas:
            'slash title'
            '.. buttons';
        grid-gap: 18px 0;
        @include desktop {
            max-height: 200px;
        }

        @include mobile {
            position: absolute;
            display: block;
            bottom: 0;
            width: 100%;
            grid-template-areas: none;
            padding: 2rem;
            background: #07182cd0;
        }

        &-Slash {
            @include slash(slash, 85%, 0, 1rem);
            @include mobile {
                display: none;
            }
        }

        &-Title {
            display: grid;
            grid-area: title;
            font-size: 20px;
            font-weight: lighter;
            align-content: center;
            line-height: 1.4 !important;
            max-width: 635px;
            margin-right: 70px;
            padding-left: 30px;
            @include mobile {
                font-size: 1.8rem;
                max-width: 100%;
                padding-left: 0;
                margin: 0 auto;
                padding-bottom: 2rem;
            }
        }
    }

    &-Button-Container {
        max-width: 370px;
        display: grid;
        grid-area: buttons;
        grid-template-columns: 200px 160px;
        text-align: center;
        align-content: center;
        padding-left: 30px;
        grid-gap: 30px;
        @include desktop {
            max-height: 35px;
        }

        @include mobile {
            max-width: 100%;
            padding-left: 0;
            margin: 0 auto;
            grid-gap: 1rem;
            @media only screen and (max-width: 400px) {
                grid-template-columns: 1fr;
            }
        }

        a {
            text-decoration: none;
            font-size: 14px;
            color: $white;
            max-height: 100%;
            @include mobile {
                padding: 2.5px;
            }

            &:nth-of-type(1) {
                @include button-secondary;
            }

            &:nth-of-type(2) {
                @include button-primary;
            }
        }
    }
}

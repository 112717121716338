/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */



.Header {
  div.SearchField {

    &-SearchWrapper {

      top: calc(var(--header-total-height) );
    }
    &-CloseIcon{
      @include desktop{
        // left: 145px;
        // top: 70px;
        z-index: 100;
      }
      &::before, &::after{
        background-color: #07182c ;
        height: 20px;
        top: 10px;
      }
    }
  }
}

.SearchPage-Heading{
  font-size: 24px;
  font-family: $font-sofia-pro-black;
}

.SearchField-SearchFieldWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.SearchField {
  &-SearchWrapper {
    &_isVisible {
      .SearchField-SearchIcon {
        float: right;

        @include desktop {
          width: 20px;
          height: 20px;
        }
      }

      .SearchField-CloseIcon {
        float: right;
      }

      .SearchField-Input {
        left: 3rem;
      }
    }
  }

  &-Text {
    font-family: 'open sans semi bold';
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.2;
    text-align: left;
  }
}

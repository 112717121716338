@import './large-hero';
@import './contact-block';
@import './newsletter-block';
@import './product-list-block';
@import './cms-front-page-product-solutions';
@import './cms-front-page-category-menu';
@import './cms-ydelser-container';
@import './cms-front-page-viden-list';
@import './cms-front-page-hamag-profilfilm';
@import './cms-contact-page';
@import './blog-basics.scss';
@import './cms-ydelser-page.scss';
@import './cms-fabrik-container.scss';
@import './cms-core-values.scss';
@import './cms-om-hamag-profilfilm.scss';
@import "./cms-basic-slider.scss";
@import "./image-reset.scss";
@import "./checkout.scss";

// .CmsPage-Content > div {
//   &:nth-of-type(1) {
//     padding-bottom: 6rem;
//     margin-top: -20px;
//     @include mobile{
//       padding-bottom: 2rem;
//     }
//   }
// }
.CmsPage-Wrapper {
  margin-bottom: 0 !important;
}

p {
  line-height: 1.5;
}

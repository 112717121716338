/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.ProductCard {
  &-Figure{
    background: #e6e6e6;
    .Image-Image{
      mix-blend-mode: multiply;
    }
  }
  &-Content {
    padding: 0;
  }
  &-Sku {
    font-family: $font-open-sans-bold;
    font-size: 1rem;
    color: darken($light-gray, 35%);
    margin-bottom: 0;
  }
  &-Name {
    font-family: $font-open-sans-bold;
    overflow: unset;
    white-space: unset;
  }
  .TaxTag{
    padding-left: 5px;
  }
  .ProductPrice {
    font-size: 1.3rem;
  }
  .ProductWishlistButton-Button.Button {
    background: var(--button-background);
  }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --my-account-order-table-row-hover-background: var(--secondary-base-color);
}

.MyAccountQuoteTableRow {
    height: 36px;

    &-DownloadLink {
        display: block;
    }
    
    &-OrderQuote {
        width: 80px;
        @include desktop {
            display: inline-block !important; 
            &:not(:first-child){
                margin-left: 10px;
            }
        }
        @include mobile {
            &:not(:first-child){
                margin-top: 10px;
            }
        }

    }

    td,
    th {
        @include mobile {
            min-width: 0;
        }
    }

    &:hover {
        background-color: var(--my-account-order-table-row-hover-background);
        cursor: pointer;
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme-abstract';

.root.product-list {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .list-title {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    justify-content: space-between;
  }

  .overall-scroll {
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
    overflow: auto;

    .images {
      width: 30%;
      flex-shrink: 0;
      margin-inline-end: 10px;
      margin-bottom: 35px;
    }

    .ProductCard {
      min-width: 20rem;
      margin-right: 1rem;

      .Image {
        img {
          height: 100%;
          width: auto;
        }
      }

      .TextPlaceholder {
        display: none;
      }
    }
  }
}

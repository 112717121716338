/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
  --header-total-height: 125px;
  --header-usp-height: 43px;


  @include mobile {
    --header-total-height: 50px;
    --menu-item-figure-background: #ffffff;
  }
}


body {
  --header-height: 50px;
}

@include desktop {
  .Header {
    background: none;
    border-bottom: 0 !important;
    position: fixed !important;
    background-color: #e6e6e6;
    padding-bottom: 15px;
    &-Nav {
      max-width: 1100px;
    }
    &-LogoWrapper {
      width: 105px !important;
      height: 30px;
      // z-index: 35 !important;
      @media only screen and (min-width: 1141px) {
        margin-left: -12px;
      }
      .Image-Image{
        object-fit: contain !important;
      }
    }
    position: relative;
    &-Wrapper {
      background: $light-gray;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 90px;
      padding: 0;
    }

    &-MyAccount {
      margin-right: 10px;
    }

    &-MyAccountWrapper {
      flex-direction: row-reverse;
      border: 2px solid var(--button-background);
      padding: 4px 12px;
      background: white;
      gap: 8px;
    }

    &-MyAccountTitle {
      display: block;
      font-size: 12px;
      color: var(--button-background);
    }

    &-MinicartButtonWrapper {
      padding-left: 5px;
      padding-right: 5px;
      margin-left: 0;
    }

    &-BurgerMenuWrapper {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 12px 22px;
      background: var(--button-background);
      gap: 8px;
    }

    &-BurgerMenuText {
      font-size: 12px;
      color: white;
      padding-right: 0.48rem;
      text-transform: uppercase;
    }

    &-Button {
      &_type_burgermenu {
        background-size: contain;
        width: 13px;
        height: 11px;
        background-image: url(icons/icon-menu-new.svg);
        opacity: 1;
        pointer-events: all;
        overflow: visible;

        &.Header-Button_isVisible {
          background-image: url(icons/icon-close-menu.svg);
          width: 11px;
          margin-left: 2px;
        }
      }

      &_type_account {
        width: 16px;
        height: 18px;
      }
    }

    .SearchField {
      &-SearchIcon {
        width: 14px;
        height: 14px;
      }
    }

    &-USPBarWrapper {
      width: 100%;
      height: var(--header-usp-height);
      background-color: white;
      margin-bottom: 12px;
    }

    &-USPBar {
      max-width: 1100px;
      height: 100%;
      padding: 0 0 5px;
      margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &-USPElementWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 40px;
    }

    &-USPElement {
      display: flex;
      flex-direction: row;
      gap: 6px;
      align-items: center;
    }

    &-USPElementIcon {
      background-size: contain;
      width: 14px;
      height: 14px;
      background-image: url(icons/checkmark-top.svg);
      opacity: 1;
    }

    &-USPElementText {
      font-family: 'open sans regular';
      font-size: 12px;
      padding-top: 1px;
    }

    &-USPButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .Menu {
    &-Overlay_isVisible{
      margin-top: 30px;
    }
    &-ItemCaption_type_main {
      text-transform: none;
    }
    &-Item {
      font-family: $font-open-sans-bold;
      font-size: 16px;
      color: $default-primary-dark-color;
    }
    &-Overlay {
      top: 0;
    }
    &-MenuWrapper {
      position: absolute;
      top: 41px;
      left: 0%;
      pointer-events: none;
      width: 100%;
      z-index: 25;
      ul.Menu-ItemList {
        max-width: min-content;
        position: absolute;
        top: 25px;
        right: 0;
        margin-right: 300px;
        @media only screen and (max-width: 1366px) {
          margin-right: 270px;
        }
        @media only screen and (max-width: 1300px) {
          margin-right: 250px;
        }
        @media only screen and (max-width: 1260px) {
          margin-right: 220px;
        }
        @media only screen and (max-width: 1200px) {
          margin-right: 200px;
        }
        @media only screen and (max-width: 1150px) {
          margin-right: 170px;
        }
        @media only screen and (max-width: 1100px) {
          margin-right: 160px;
        }
      }
    }
    &-SubCategories {
      border-top: 2px solid darken($light-gray, 5%);
      .Menu-ItemList {
        background: $light-gray;
        justify-content: space-evenly;
      }
    }
    &-SubCategoriesWrapper {
      background: $light-gray;
      margin-top: 5px;
      padding-top: 25px;

          .Menu-ItemCaption{
            font-size: 18px;
            padding-bottom: 10px;
          }
          .Menu-SubLevelDesktop{
            .Menu-ItemCaption{
              font-size: 16px;
              padding-bottom: 0;
          }
      }


      &_isVisible {
        top: 55px;
      }
      &::before{
        margin-top: 55px;
      }
      &Inner {
        background: $light-gray;
        .Menu-SubCategories {
          background: $light-gray;
          &::before {
            border: 1px solid black;
          }
        }
      }
    }
  }
}

@include mobile {
  .Header_name_no-match {
    pointer-events: auto !important;
    opacity: 1 !important;

    nav.Header-Nav {
      pointer-events: none;
      opacity: 0;
    }
  }

  .Header {
    height: var(--header-height);

    &-Button {
      &_type_account {
        background-image: url(icons/icon-account-mobile.svg);
      }
    }
  }
  .Header-Nav{
  }
  .Menu-MainCategories{
    margin-top: 1rem;
  }
  .Menu{
    &-ItemFigure_type_subcategory{
      // background-color: var(--menu-item-figure-background);
    grid-auto-flow: column;
    grid-template-columns: 100%;
    grid-gap: 5px;
    height: 50px;
    figcaption{
      padding: 0 !important;
      // font-size: 2.1rem;
      // font-weight: bold;
      text-transform: uppercase;
      // padding-left: 30px;
    }
    }
    &-ItemFigure_type_main, &-ItemFigure_type_subcategory,&-SubItemWrapper{
      padding-left: 30px;
      border-bottom: 1px solid $light-gray;
      margin: 0 !important;
      height: 50px;
      figcaption {
        text-transform: capitalize;
        font-size: 21px !important;
        font-weight: bold;
      }
    }
    &-Link{
      height: 50px !important;
    }
 .Menu-ItemFigure{
   height: 50px !important;
   border-bottom: 1px solid $light-gray;
   figcaption{
     height: 100%;
   }
 }

  }

  .Menu-SubMenu_isVisible{
  .Menu-Link{
    background-color: var(--menu-item-figure-background);
    height: 50px;
    border-bottom: 1px solid $light-gray;

  .Menu-ItemFigure{
    height: 100%;
    font-size: 2.1rem;
    font-weight: bold;
    text-transform: uppercase;

}
}
}
  Header {
    background-color: $light-gray !important;
    &-Nav {
      height: 0;
    }

    .NavigationTabs-Button {
      .Header-Button_type_minicart {
        width: 35px !important;
        background-size: cover !important;
      }
    }
  }
}

@media only screen and (max-width: 1270px) and (min-width: 1024px) {
  .Menu-SubMenu {
    .Menu-ItemList_type_subcategory {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 25px 0;
    }
  }
}

.Header {
  &-MinicartItemCount {
    background: $default-base-orange-color;
  }
}


.Header-Wrapper ~ .HomePage{
  @include desktop{
    // padding-top: 60px;
  }

}
.Header-Wrapper ~ .PostsListing, .PostsDetails, .Checkout{
  @include desktop{
    padding-top: 60px;
  }

}

.MenuOverlay-Results{
  @include mobile{

  }
}

#root {
  top: 40px;

  @include mobile {
    top: 0;
  }
}

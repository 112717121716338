/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --price-color: #{$black};
  --price-with-discount-color: #b10d0d;
  --price-discount-color: #808080;
}

.ProductPrice {
  &-RegularPrice {
    margin-bottom: -8px;
    margin-top: 10px;
    order: 7;
    color: gray;
    font-weight: 700;
    font-size: 12px;
    font-family: "open sans bold";
  }

  .TaxTag {
    color: gray;
    font-size: 1rem;
  }

  &_hasDiscount {
    color: var(--price-color);
  }

}

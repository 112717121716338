.CategoryMenu {
  padding: 75px 0;
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
    padding: 5rem 2rem;
  }
  &-Header {
    margin: 3rem 0 4rem 0;
    h3 {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      font-size: 50px;
      @include mobile {
        font-size: 3rem !important;
      }
      &::after {
        content: '';
        display: block;
        margin: 0 auto;
        max-width: 60px;
        padding-top: 40px;
        border-bottom: 1rem solid $default-base-orange-color;
      }
    }
  }
  &-Container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 30px;
    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
    }
    @include mobile {
      grid-template-columns: 1fr 1fr;
      grid-gap: 2rem;
    }
    a {
      border-left: 1px solid $light-gray;
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      text-align: center;
      font-weight: bold;
      transition: 0.1s ease-in-out;
      font-size: 16px;
      padding-bottom: 20px;
      &:hover {
        transform: scale(1.05);
        border: 1px solid $light-gray;
        background: $white;
        z-index: 10;
        text-decoration: none !important;
      }
      &:last-of-type {
        border-right: 1px solid $light-gray;
      }

      @include mobile {
        border: 1px solid gray;
      }
      img {
        padding: 5rem 0;
        max-width: 40%;
        margin: 0 auto;
        @include mobile {
          // padding: 3rem 5rem;
          max-width: 60%;
        }
      }
    }
  }
  .Button-Categories {
    padding: 1rem;
    border: 2px solid $default-primary-dark-color;
    text-align: center;
    margin: 0 auto;
    text-decoration: none;
    font-size: 16px;
    transition: 0.2s ease-in-out;
    
    &:hover {
      background: $default-primary-dark-color;
      color: $white;
    }
  }
}

.CheckoutOrderSummary {
    &-Header {
        text-transform: none!important;
    }
}

.CheckoutPayments {
    margin-top: 2rem!important;
}

.Checkout-Coupon {
    display: none!important;
}

.Checkout-Step > .Checkout-Title {
    display: none!important;
}

.CartComment-Input {
    textarea {
        width: 100%;
    }
}

.Checkout-Comment {
    span {
        @include desktop {
            font-family: "sofia pro black";
            font-size: 1.8rem;
        }
    }
}

.isCheckout {
    .Header-Nav {
        @include mobile {
            display: none!important;
        }
    }
}

.Checkout {
    @include desktop {
        padding-top: 0!important;
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 8rem;
    }
}

.CheckoutBilling-TACLink {
    margin-left: 1rem;
}

.CartItem {
    &-Heading {
        font-weight: bold;
        color: #8b8b8b!important;
    }
}

.CheckoutOrderSummary {
    &-Text {
        font-size: 1.3rem!important;
        &:first-child {
            // font-weight: normal;
        }
    }
}
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
  --breadcrumbs-height: 40px;
  --breadcrumbs-background: #07182c;
  --breadcrumbs-active-color: #888;
  --breadcrumbs-color: #fff;
}

.Breadcrumbs {
  margin-top: 60px;
  background-color: var(--button-background);
z-index: 10;
  $crumb-padding: 20px;
  $arrow-size: 4px;

  @include desktop {
    margin-top: 0;
  }

  @include before-desktop {
    --breadcrumbs-background: #fff;

    display: none;
  }

  &-List {
    list-style: none;
    padding: 5px 0;
    max-width: 1100px;
    margin: 0 auto;
  }

  .isCmsPage & {
    display: none;
  }

}

:root {
  --component-background: $white;
  --base-width: 1100px;
}
.CmsPage-Content {
  .HamagContact {
    color: $default-primary-base-color;
    &-Fabrik,
    &-Marketing,
    &-Service,
    &-Formular {
      margin: 0 auto;
    }
    .P-Bold {
      font-weight: 700;
      font-size: 18px;
      @include mobile {
        font-size: 16px;
      }
    }
    &-Banner {
      .Image {
        height: 0 !important;
        @include desktop {
          padding-bottom: 600px!important;
        }
        @include mobile {
          padding-bottom: 60% !important;
        }

        .Image-Image {
          object-fit: cover !important;
          position: absolute !important;
          height: 100% !important
        }

      }
    }
    &-Content {
      @include desktop {
        margin-top: -69px;

        width: 1100px;
      }
      padding-top: 6rem;
      padding-left: 9rem;
      padding-right: 9rem;
      background: $white;
      margin: 0 auto;
      z-index: 10;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 3rem;

      @include mobile {
        display: block;
        width: 100%;
        padding: 2rem;
      }

      h2 {
        font-size: 40px !important;
        text-transform: none;
        
      }
      p {
        font-size: 18px;
      }
    }
    &-Links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      font-size: 18px;
      font-weight: 700;
      @include mobile {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        padding-bottom:1.68rem;
      }
    }
    &-PhoneBlock {
      display: grid;
      grid-template-columns: 1fr 11fr;
      grid-gap: 2rem;
      .PhoneIcon {
        background-image: url(images/phone-pos.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &-MailBlock {
      display: grid;
      grid-template-columns: 1fr 11fr;
      grid-gap: 2rem;
      .MailIcon {
        background-image: url(images/mail-pos.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &-Info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'salg addresse'
        'service addresse';
      @include mobile {
        grid-template-areas:
          'salg'
          'service'
          'addresse';
        grid-template-columns: 1fr;
        grid-gap: 4rem;
      }
    }
    &-SalgMail {
      grid-area: salg;
    }
    &-ServiceMail {
      grid-area: service;
    }
    &-Adresse {
      grid-area: addresse;
    }

    &-Fabrik {
      max-width: var(--base-width);
      margin-top: 3rem;
      @include tablet {
        width: 100%;
      }
      @include mobile {
        width: 100%;
      }
    }

    &-Marketing {
      max-width: var(--base-width);
      margin: 5rem auto;
      display: grid;
      grid-template-columns: 1fr;
      @include mobile {
        width: 90%;
      }
      h2 {
        text-transform: none;
        font-size: 36px;
      }
    }
    &-Service {
      max-width: var(--base-width);
      margin: 5rem auto 10rem auto;
      display: grid;
      grid-template-columns: 1fr;
      @include mobile {
        width: 90%;
        margin: 2rem auto;
      }
      h2 {
        text-transform: none;
        font-size: 36px;
      }
    }
    &-ProfileList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 3rem;
      @include tablet {
        grid-template-columns: 1fr 1fr;
      }
      @include mobile {
        grid-template-columns: 1fr 1fr;
      }
    }
    &-ProfilCard {
      display: grid;
      grid-template-columns: 1fr;
      p {
        margin: 0;
        &:first-of-type {
          padding-top: 2rem;
        }
      }

      a {
        font-size: 16px;
        &:first-of-type {
          margin-top: 1rem;
        }
        @include mobile {
          font-size: 14px;
        }
      }
    }

    &-Formular {
      max-width: var(--base-width);
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: $default-primary-dark-color;
      padding: 5rem;
      @include tablet {
        grid-template-columns: 1fr;
        .Image {
          display: none !important;
        }
      }
      @include mobile {
        grid-template-columns: 1fr;
        .Image {
          display: none !important;
        }
      }
    }
  }
}
.ProductSolutions {
    display: grid;
    grid-template-columns: 1fr;
    padding: 75px 5rem;
    background-image: url(images/Hamag-Background-Globe-Dots-Black.png);
    background-size: cover;
    background-repeat: no-repeat;

    @include mobile {
        padding: 0;
        padding-top: 4.8rem;

        h2 {
            margin-top: 0;
        }
    }

    &-Header {
        max-width: 1100px;
        margin: 5rem auto;
        padding-right: 150px;
        @include tablet {
            width: 100%;
            padding-right: 0;
            margin: 10rem 0 0 0;
        }
        @include mobile {
            width: 100%;
            padding-right: 0;
            margin: 0;
        }

        h2 {
            font-size: 50px;
            line-height: 55px;
            text-transform: none;

            @include mobile {
                font-size: 24px !important;
                text-align: center;
            }
        }

        .RedText {
            color: $default-base-orange-color;
        }
    }

    &-Content {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        grid-gap: 5rem;
        max-width: 1100px;
        margin: 0 auto;
        @include tablet {
            grid-template-columns: 1fr;
            grid-template-areas:
                'img'
                'text';
        }
        @include mobile {
            grid-template-columns: 1fr;
            grid-template-areas:
                'img'
                'text';
            margin: 0 2.5rem;
        }

        p {
            font-size: 18px;
            margin-bottom: 1.8rem;

            @include mobile {
                padding-top: 0;
            }
        }

        .Image_ratio_square {
            @include mobile {
                grid-area: img;
            }
        }
    }

    &-Text {
        display: grid;
        align-content: center;
        @include mobile {
            grid-area: text;
            width: 100%;
            grid-template-columns: 1fr;
            padding-bottom: 2.52rem;
        }
    }

    .Button-Primary {
        max-width: 170px;
        text-align: center;
        padding: 10px;
        border: 1px solid $default-primary-dark-color;
        color: $default-primary-dark-color;
        font-size: 18px;
        text-decoration: none;
        transition: .3s ease-in-out;

        @include mobile {
            max-width: 100%;
            margin: 0 auto;
        }

        &:hover {
            background: $default-primary-dark-color;
            color: $white;
        }
    }
}

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import 'abstract/variables';
@import 'abstract/media';
@import 'abstract/button';
@import 'abstract/loader';
@import 'abstract/icons';
@import 'abstract/parts';
@import 'abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import './abstract/abstract';

@import './base/breakpoints';
@import './base/keyframes';
@import './base/root';
@import './base/reset';
@import './base/button';
@import './base/list';
@import './base/link';
@import './base/table';
@import './base/input';
@import './base/fieldset';
@import './base/blockquote';
@import './cms/block';
@import './theme-specific-styles/import';
@import './base/font';


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-sofia-pro-black;
    line-height: 1.2 !important;
}

@include mobile{
  h2{
    font-size: 2rem !important;
  }
}
.Blog-Wrapper{
  h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem !important;
}
}

.Title {
  font-family: $font-muli;
  font-weight: bold;
}
.Cover-TextBox-Title {
  font-family: $font-muli;
}

p,
li {
  font-family: $font-open-sans-regular;
  font-size: 16px;
}

@include mobile {
  .NavigationTabs-LogoImg {
    max-width: 100px !important;
    max-height: 35px !important;
  }
}


h2.SomethingWentWrong-SubHeading{
  text-transform: capitalize !important;
}
a.SomethingWentWrong-Button{
  font-size: 1.6rem !important;
}
.NewVersionPopup-ReloadButton, .Button-isLikeLink{
  font-size: 16px;
}

.HideOnMobileSlider{
  @include mobile{
  display: none;
}
}
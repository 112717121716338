
.Viden-Page {
  background: $white;
  .Blog-Wrapper {
    background: $white;
  }
  .FeaturedBlogContainerTop {
    margin-top: 0;
  }
  .FeaturedBlogContainerTop.No-Bg {
    
    background: none;
    .YdelserTitle {
      color: $default-primary-dark-color;
    }
   
    
    
  }

  .Inspiration-Tips {
    display: flex;

    max-width: 1100px;
    margin: 75px auto;
    position: relative;
    
    @include mobile {
      flex-wrap: wrap;
    }
    .Image {
      @include desktop {
        width: 60% !important;
        margin-left: auto;
        z-index: 9;
        padding-bottom: 0!important;
      }
      @include mobile {
        width: 100% !important;
      }

      .Image-Image {
        margin-left: auto;

        height: 600px !important;
        object-fit: cover;
        @include desktop {
          max-width: 60% !important;
        }
        @include mobile {
          max-width: 100%;
        }
      }
    }
    &-Text-Container {
      background: $light-gray;
      @include desktop {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 10%;
        width: 50%;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.164);
        padding: 5rem;
        padding-bottom: calc(5rem + 2.4rem);
        margin-right: -5rem;
      }
      @include mobile {
        top: 0%;
        padding: 4rem 2rem;
        padding-bottom: calc(4rem + 2.4rem);
      }

      h2 {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 38px;
        text-transform: lowercase;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      p{
        margin-bottom: 2.4rem;
      }
      a {
        border: 1px solid $default-base-orange-color;
        background: $default-base-orange-color;
        padding: 1rem;
        color: $white;
        max-width: 50%;
        text-align: center;
        text-decoration: none;
        transition: 0.2s ease-in-out;
        &:hover {
          background: transparent;
          color: $default-base-orange-color;
        }
      }
    }
  }

  .Text-Box-White-Bg {
    background: $white;
    @include mobile {
      padding: 2rem;
    }
    & > div {
      max-width: 1100px;
      margin: 0 auto;
      @include desktop {
        padding: 75px 0;
      }
      @include mobile {
        padding: 2.52rem 0;
      }
    }
    h2 {
      text-transform: lowercase;
      @include desktop {
        font-size: 50px;
      }
      @include mobile {
        font-size: 32px;
      }
      &::first-letter {
        text-transform: uppercase;
      }
    }
    p {
      font-size: 16px;
    }
  }
}

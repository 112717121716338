/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../style/abstract/variables';
@import '../../../../style/abstract/media';
@import '../../../../style/abstract/button';
@import '../../../../style/abstract/loader';
@import '../../../../style/abstract/icons';
@import '../../../../style/abstract/parts';
@import '../../../../style/abstract/theme-abstract';

.carefree-container {
  overflow: hidden;

  .inner-container {
    display: flex;
    flex-wrap: wrap;

    .title {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
      justify-content: space-between;
    }

    .child-container {
      display: flex;
      width: 100%;
      flex-wrap: nowrap;
      overflow: auto;
      scroll-behavior: smooth;
      /* this will hide the scrollbar in mozilla based browsers */
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
      /* this will hide the scrollbar in internet explorers */
      -ms-overflow-style: none;
      align-items: flex-end;

      &::-webkit-scrollbar {
        display: none;
      }

      .ProductCard {
        min-width: 25rem;
      }
    }
  }
}

.Has-Bg {
    background: #07182c;

    .VidenTitle {
        color: white;
    }

    .VidenContainer .post-list-wrapper .post-holder {
        background: $light-gray !important;
    }

    .AllCases {

        border: 1px solid $default-base-orange-color;
        color: $white;

        &:hover {
            text-decoration: none;
            background: $default-base-orange-color;
        }
    }
}

.viden-page .post-ftimg-hld,
.front-page .post-ftimg-hld,
.Has-Bg .post-ftimg-hld {
    background: #07182c;
}

.RenderWhenVisible + .AllCases {
    display: block;
    z-index: 10;
    @include desktop {
        bottom: 35px;
    }
    @include mobile {
        bottom: 35px;
    }
}
@include mobile {
    .hasLogoList {
        bottom: -50px !important;
    }
}

.AllCases {
    display: none;
    position: absolute;
    left: 50%;
    font-family: $font-open-sans-bold;
    transform: translateX(-50%);
    padding: 15px 20px;
    transition: .2s ease-in-out;
    font-size: 12px;
}

.No-Bg {
    // padding-bottom: 130px !important;

    .AllCases {

        border: 1px solid $default-primary-dark-color;
        color: $default-primary-dark-color;

        &:hover {
            background: $default-primary-dark-color;
            color: $white;
        }
    }
}
@include desktop {
    .FeaturedBlogContainer > .RenderWhenVisible {
        padding-bottom: 100px;
    }

    .VidenContainer > .RenderWhenVisible {
        padding-bottom: 100px;
        width: 100%;
    }
}
@include mobile {
    .FeaturedBlogContainer > .RenderWhenVisible {
        padding-bottom: 20px;
        margin-bottom: 75px;
    }
}

.CmsPage-Content {
    .VidenContainer,
    .FeaturedBlogContainer {

        display: grid;
        grid-template-columns: .1fr 1fr;
        grid-template-areas:
            'SectionTitle MainTitle'
            'Grid Grid';
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        padding: 75px 0;
        padding-bottom: calc(75px + 2.16rem);
        @include mobile {
            padding: 0 2rem;
            grid-template-areas:
                'SectionTitle SectionTitle'
                'MainTitle MainTitle'
                'Grid Grid';
        }

        .VidenSectionTitle {
            color: $default-base-orange-color;
            padding-top: .7rem;
            text-transform: uppercase;
            grid-area: SectionTitle;

            @include mobile {
                padding-left: 0;
            }
        }

        .VidenTitle {
            grid-area: MainTitle;
            font-size: 50px;
            text-align: center;
            font-weight: 700;
            max-width: 80%;
            line-height: 55px;
            margin-left: 60px;
            margin-bottom: 50px;
            text-transform: none;
            @include mobile {
                text-align: left;
                padding: 0;
                font-size: 3rem;
                margin-left: 0;
                max-width: 100%;
            }
        }

        .post-list-wrapper {
            grid-area: Grid;
        }
    }
}

.Logo-List {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    align-content: center;
    max-width: 1400px;

    @include desktop {
        height: 245px;
        justify-content: space-evenly;
    }
    @include mobile {
        height: 245px;
        flex-wrap: wrap;
        justify-content: space-around;
        @media only screen and (max-width: 600px) {
            padding: calc(2rem + 50px) 2rem 2rem 2rem;
        }
    }

    div {
        background-repeat: no-repeat;
        background-size: contain;
        filter: brightness(0) invert(1);
        width: 225px;
        height: 90px;
        @include desktop {
            width: 225px;
        }
        @include mobile {
            width: 20%;
            height: 70px;
            @media only screen and (max-width: 600px) {
                width: 33%;
            }
        }
    }
}

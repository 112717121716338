.Core-Values {
  background: $default-primary-dark-color;
  color: white;
  padding: 2rem 3rem;
  h2 {
    text-align: center;
    font-size: 32px;
    text-transform: lowercase;
    font-family: $font-sofia-pro-black;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  p {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  &-list {
    max-width: 1100px;
    margin: 3rem auto;
  }
  .Table-Wrapper {
    overflow: hidden;
  }
  tr {
    background: $default-primary-dark-color !important;
    td {
      padding: 2rem 0;
      vertical-align: top !important;
      @include mobile {
        display: block;
        padding: 1rem 0;
      }
      &:nth-of-type(1) {
        font-size: 32px;
        // font-weight: bold;
        min-width: 350px;
        font-family: $font-sofia-pro-black;
      }
      &:nth-of-type(2) {
        align-content: center;
        font-size: 16px;
      }
    }
    span {
      color: $default-base-orange-color;
    }
  }
}

:root {
  --base-width: 1000px;
}
.Contact-Container {
  padding-bottom: 4rem;
  // background: linear-gradient(0deg, $light-gray 60%, rgba(0, 0, 0, 0) 30%);
  @include mobile{
    padding-bottom: 0;
  }
}
.Contact-Section {
  color: $white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: var(--base-width);
  margin: 2rem auto;
  background-color: $default-primary-dark-color;
  align-content: center;

  @include mobile {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }

  &-Title {
    max-width: 100%;

    @include tablet {
      padding-top: 0;
    }
    @include mobile {
      padding-top: 0;
    }
    h4 {
      font-size: 16px;
      text-transform: uppercase;
      font-family: $font-muli;
    }
    h3 {
      margin: 1rem 0;
      // max-width: 80%;
      font-size: 40px;
      line-height: 1;
    }
  }

  .ImgContainer {
    @include tablet {
      display: none;
    }
    @include mobile {
      display: none;
    }
    padding: 2rem 5rem;
    .Image_ratio_square {
      width: 100%;
      height: 100%;
    }
    img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  .ContactArticle {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 0 5rem;
    // grid-template-rows: 0.3fr 0.2fr 0.2fr 0.5fr;
    align-content: center;
    padding-left: 5rem;
    @include mobile {
      text-align: center;
      padding: 5rem 1rem;
      grid-template-rows: 1fr 0.3fr 0.3fr;
    }

    p {
      font-size: 1.6rem;
    }
  }
  .p-span {
    display: flex;
    margin-right: auto;

    @include mobile {
      margin: 0 auto;
      display: grid;
      grid-template-columns: 0.1fr 1fr;
      grid-gap: 2rem 0;
    }
    .phone-icon {
      background-image: url(images/phone-neg.svg);
      width: 2vw;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 1rem;
      @include mobile {
        width: 8vw;
        height: 2.5vh;
        margin-top: 0;
        margin-right: 0.5rem;
        margin-left: 2rem;
      }
    }
    .mail-icon {
      width: 2vw;
      margin-left: 2rem;
      background-image: url(images/mail-neg.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 1rem;
      @include mobile {
        width: 8vw;
        height: 2.5vh;
        margin-top: 0;
        margin-right: 0.5rem;
        margin-left: 2rem;
      }
    }
    &-left,
    &-right {
      text-align: left;
    }

    a {
      font-size: 1.6rem;
      color: $white;
    }
  }
  .Button-Container {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 30px;
    margin-right: 2rem;
    align-items: center;
    @include mobile {
      flex-wrap: wrap;
      max-width: 250px;
      margin: 0 auto;
      grid-template-columns: 1fr;
    }
    a {
      display: block;
      color: $white;
      text-align: center;
      font-size: 1.6rem;
      padding: 10px 5px !important;
      width: 50%;
      @include mobile {
        width: 100%;
      }
      &:first-of-type {
        @include button-secondary;
        
        max-width: 120px;
        @include mobile {
          max-width: 100%;
        }
      }
      &:last-of-type {
        @include button-primary;
        max-width: 170px;
        @include mobile {
          max-width: 100%;
        }
      }
      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

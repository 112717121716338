  .control-dots{
    bottom: 50px !important;
    left: 50% !important;
    transform: translateX(-50%);
    @include desktop{
      max-width: 500px;
    }
    @include mobile{
      max-width: 180px;
    }
    .dot{
      box-shadow: none !important;
      border-radius: 0 !important;
      background: #07182C !important;
      height: 5px !important;
      padding-left: 0 !important;
      @include desktop{
        width: 50px !important;
      }
      @include mobile{
        width: 20px !important;
      }
      &::before{
      content: "" !important;
      }
    }
  }
   
  
.carousel-root{
  background: #E6E6E5;
}
  .slider{
      
  .slide{
    padding-left: 0 !important;
    &::before{
      content: "" !important;
    }
    
    }
    .SliderWidget-Figure{
      @include desktop{
        max-width: 1400px;
       margin: 0 auto;
       padding: 6rem 12.6rem 13.2rem 12.6rem;
      }
      @include mobile{
        margin: 6rem 2rem 12.6rem 2rem;
      }
    }
  }
.control {
&-arrow{
  display: block !important;
  border: 1px solid #07182C !important;
 
  top: unset !important;
  bottom: 50px !important;
  background-repeat: no-repeat  !important;
    background-size: 10px 15px !important;
    background-position: center !important;
    height: 40px !important;
    width: 40px !important;
   
    &:hover{
      background: #07182C !important;
      background-repeat: no-repeat  !important;
    background-size: 10px 15px !important;
    background-position: center !important;
    }
    
  &::before{
    display: none !important;
  }

}
&-next{
  background-image: url(./images/slider/arrow-right-no-bg.svg) !important;
  
  @include desktop{
    right: 50% !important;
    transform: translateX(280px);
  }
  @include mobile{
    margin-right: 2rem;
  }
  &:hover{
    background-image: url(./images/slider/arrow-right.svg) !important;
  }
}
&-prev{
  background-image: url(./images/slider/arrow-left-no-bg.svg) !important;
  @include desktop{
    left: 50% !important;
    transform: translateX(-280px);
  }
  @include mobile{
    margin-left: 2rem;
  }
  &:hover{
    background-image: url(./images/slider/arrow-left.svg) !important;
  }
}

}


.Blog-Wrapper{
  .RenderWhenVisible{
    padding: 0;
  }
  .carousel-root{
    background: transparent !important;
    padding: 0 0 50px 0!important;
    & > div {
      padding: 0 !important;
    }
    .Image{
      position: relative !important;
      padding-bottom:43%;
    }


    .slider-wrapper{
      padding: 0;
    }
    .slider .SliderWidget-Figure{
      max-width: 100%;
      margin: 0;
      padding: 6rem 0 13.2rem 0;
    }
.control{
  &-prev, &-next{
    margin: 0 !important;
  }
}
  }
}
.history-slider-title{
  font-size: 3.2rem;
  font-family: $font-open-sans-bold !important;
  max-width: 1050px;
  
  margin: 0 auto;
  color: #07182C;
  @include desktop{
    padding-top: 3rem;
  }
  @include mobile{
    padding-top: 5rem;
    padding-left: 2rem;
    font-size: 3.2rem !important;
  }
  &::after{
    content:"";
    display: inline-block;
    height: 0.5em;
    vertical-align: bottom;
    margin-right: -100%;
    
    border-top: 1px solid #07182C;
    @include desktop{
       width: 260px;
       margin-left: 70px;
    }
    @include mobile{
      max-width: 160px;
      min-width: 120px;
      margin-left: 15px;
    }
  }
}
.Blog-Wrapper-Header ~ div > .RenderWhenVisible{
  .slide{
    opacity: 0 ;
    transition: .2s ease-in-out;
    & +.selected {
      opacity: 1 !important;
    }
  }
  .SliderWidgetStatus{
    &-Total{
      opacity: .3;
      margin-left: 10px;
    }
    &_isLast{
      .SliderWidgetStatus-Total{
      opacity: 1;
    }
    }
  }
  
 .carousel-status{
   position: absolute;
   color: #07182C;
   text-shadow: none !important;
  @include desktop{
   top: 610px;
    left: 45%;
    transform: translateX(-295px);
    font-size: 5rem;
    font-weight: bold;
    }
    @include mobile{
      display: none;
      }

 }
  .carousel-root{
    background: $white;
  }
  .SliderWidget-Figure{
    max-width: 635px;
    position: relative;
    @include desktop{
      padding: 11rem 0 25rem 0;
    }
    @include mobile{
    padding: 0 0 25rem 0;

      margin-left: auto;
      margin-right: auto;
    }
    
  }
  .SliderWidget-Title{
    position: absolute;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #07182C;
    @include desktop{
    top: -455px;
    right: -215px;
    font-size: 186px;
    }
    @include mobile{
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 66px;
      }
  }
  .SliderWidget-Text{
    position: absolute;
    bottom: -325px;
    left: 50%;
    transform: translateX(-50%);
    @include mobile{
      bottom: -270px;
    }
    p{
      font-size: 16px;
      width: 450px;
      font-weight: 700;
      text-align: left;
      @include mobile{
        font-size: 14px;
        width: 305px;
      }
    }
  }

  .control-dots{
    
    @include desktop{
      max-width: 500px;
      margin-left: -30px;
    }
    @include mobile{
      max-width: 260px;
    }
    .dot{
      box-shadow: none !important;
      border-radius: 100% !important;
      background: #07182C !important;
      height: 8px !important;
      padding-left: 0 !important;
        width: 8px !important;
        @include desktop{
        margin: 0 80px 0 0;
          &:nth-of-type(1){
            margin-left: 80px;
          }
          &:last-of-type{
            margin: 0;
          }
        }
        @include mobile{
          margin: 0 14px;
        }
      &::before{
      content: "" !important;
      }
      &::after{
        content:"";
    display: block;
    height: 0.5em;
    vertical-align: bottom;
    
    margin-left: 5px;
    border-top: 2px solid #07182C;
    opacity: .3;
    @include desktop{
      width: 83px;
      margin-top: 0.3rem;
    }
    @include mobile{
      width: 31px;
      margin-top: 0.3rem;
    }
      }
      &:last-of-type{
        &::after{
        display: none;
      }
      }
    }
  }
  
}

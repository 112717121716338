.CmsPage {
  &-Wrapper {
    margin-top: 0 !important;
  }
  &-Heading {
    display: none !important;
  }

}

.PostsDetails {
  @include desktop {
    padding-top: 0 !important;
    margin-top: var(--header-total-height);
  }

  .Blog-Wrapper-Header {
    .Image {
      padding-bottom: 600px!important;
      margin-top: 0!important;
      z-index: -1;
      max-width: 100%;
      height: 0!important;

      img {
        object-fit: cover!important;
        position: absolute!important;
        height: 100%!important;
      }
    }
  }
}

.PostsDetails .Blog-Wrapper {
  &-Header {
    div {
      &:nth-of-type(2) {
        margin-bottom: 0!important;
      }
    }
  }

  .PostsDetails-Content {
    margin-top: 1.8rem;
    padding-top: 0;
  }
}

.CmsPage .Blog-Wrapper, .PostsDetails .Blog-Wrapper {
  &-Header {
    // padding-bottom: 6rem;
    p {
      &:nth-of-type(1) {
        padding-left: 0;
      }
    }
    .Image {
      margin-top: 0 !important;
      z-index: -1;
      max-width: 100%;
      height: 0 !important;
      @include desktop{
      padding-bottom: 600px!important;
      }
      @include mobile{
        padding-bottom: 60% !important;
      }

      .Image-Image {
        object-fit: cover !important;
        position: absolute !important;
        height: 100%!important;
      }
    }
    div {
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem;
      @include desktop {
        // padding: 5rem 5rem;
      }
      @include mobile {
        // padding: 5rem 2rem;
      }
      &:nth-of-type(2) {
        @include desktop {
          margin-top: -9rem;
          margin-bottom: 10rem;
          background: $white;

          p {
            padding-left: 9rem;
            padding-right: 9rem;
          }
        }
        @include mobile {
          h2 {
            padding-top: 0;
            padding-left: 2rem;
            padding-right: 2rem;
          }
          p {
            padding-left: 2rem;
            padding-right: 2rem;
          }
        }
      }
    }

    .PostsDetails-Title,
    h2 {
      grid-area: title;
      text-transform: none !important;
      text-align: left;
      color: $default-primary-dark-color;
      @include desktop {
        font-size: 50px;
        padding-top: 6rem;
        padding-left: 9rem;
        padding-right: 9rem;
      }
      @include mobile {
        padding-left: 2rem;
        margin: 0;
      }
    }
    h3,
    p {
      @include desktop {
        padding-left: 9rem;
      }
      @include mobile {
        padding-left: 2rem;
      }
    }
  }
  .Blog-Title-Description {
    padding-top: 0 !important;
    display: grid;
    @media only screen and (min-width: 601px) {
      grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'text text text'
    'PT1 PT2 PT3'
    'PP1 PP2 PP3';
    }
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    grid-template-areas:
      'text '
      'PT1'
      "PP1"
      "PT2"
      "PP2"
      "PT3"
      "PP3";
      }
    p {
      &:first-of-type {
        grid-area: text;
      }
      &:nth-of-type(2) {
        grid-area: PP1;
      }
      &:nth-of-type(3) {
        grid-area: PP2;
      }
      &:nth-of-type(4) {
        grid-area: PP3;
      }
    }
    h5 {
      font-family: $font-muli;
      &:first-of-type {
        grid-area: PT1;
      }
      &:nth-of-type(2) {
        grid-area: PT2;
      }
      &:nth-of-type(3) {
        grid-area: PT3;
      }
    }
  }
  h2 {
    @include desktop {
      font-size: 50px !important;
    }
    @include mobile {
      font-size: 24px !important;
    }

    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  h3 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  h5 {
    text-transform: uppercase;
    font-weight: lighter;
    & + p {
      font-weight: bold;
    }
  }
  p {
    font-size: 16px !important;
  }

  div {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
  }

  & > div {
    @include desktop {
      padding: 1.5rem 9rem;
    }
    @include mobile {
      padding: 1.5rem ;
    }
  }

  .Quote,
  .Quote-Colored {
    position: relative;
    .Quote-Marks {
      margin-top: -25px;
      margin-left: -25px;
      height: 100px;
      width: 100px;
      background: url('images/QuotationMarks.png');
      background-repeat: no-repeat;
      background-size: contain;
      @media only screen and (min-width: 601px) {
        position: absolute;
      }


    }

    h4,
    p {
      @media only screen and (min-width: 601px) {
        margin-left: 70px;
      }


    }
    p {
      font-style: italic;
      margin-top: 15px;
    }
  }
  .Quote-Colored {
    background: $default-primary-dark-color;
    max-width: 900px;
    .Quote-Marks {
      @media only screen and (min-width: 1024px)  {
        margin-left: -40px;
      }
      @media only screen and (min-width: 601px) and (max-width: 1023px) {
        margin-left: -15px;
      }
      @media only screen and (max-width: 600px) {
        margin-left: -10px;
      }
    }
    h4,
    p {
      color: white;
      @media only screen and (max-width: 600px) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  .PDF {
    display: grid;
    align-items: center;
    border: 1px solid gray;
    max-width: 900px;
    margin-top: 5rem;
    padding-top: 0;
    padding-bottom: 0;
    @include desktop {
      grid-template-columns: 1fr 8fr 3fr;
      grid-template-areas:
        'img title link'
        'img text link';
    }
    @include mobile {
      grid-template-columns: 1fr ;
      grid-template-areas:
        'title'
        'text'
        'link';
      padding: 2.16rem;
    }
    h3 {
      grid-area: title;
      margin-bottom: 0px;
      @include mobile{
        margin-top: 0;
      }
    }
    p {
      grid-area: text;
      font-weight: lighter;
      color: gray;
    }
    a {
      grid-area: link;
      display: block;
      background: $default-base-orange-color;
      text-align: center;
      padding: 2rem;
      color: white;
      transition: 0.2s ease-in-out;
      @include mobile {
        min-width: 100%;
        margin-right: auto;
      }
      &::before {
        content: '\27A1';
        display: inline-block;
        transform: rotate(90deg);
        border-right: 1px solid transparent;
        border-image: linear-gradient(
          to top,
          transparent 30%,
          rgb(255, 255, 255) 15%,
          rgb(255, 255, 255) 65%,
          transparent 25%
        );
        border-image-slice: 1;
      }
      &:hover {
        background: $default-primary-base-color;
      }
    }
    &-Page {
      @include desktop{
        grid-area: img;
      background: url('images/PDF-Page.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      min-width: 80px;
      width: 100px;
      height: 75px;
      margin: 0;
      }
      @include mobile{
        display: none;
      }

    }

    &-Link {
      color: $default-base-orange-color;
      font-size: 16px !important;
      font-weight: bold;
    }
  }

  .Image {
    margin-top: 0;
    max-height: auto;
  }

  .Eight-Hundred {
    max-width: 730px;
  }
  .Nine-Hundred {
    max-width: 921px;
  }
  .One-Thousand {
    max-width: 1110px;
  }
  .Eight-Hundred,
  .Nine-Hundred,
  .One-Thousand {
    height: auto;
    .Image {
      width: 100%;
      padding-bottom:62%;
    }
  }

  .Blog-Grid-Images {
    max-width: 1200px;
    // display: flex;
    // flex-wrap: wrap;
    padding: 0 !important;
    display: grid;
    @include desktop {
      grid-template-columns: 3fr 3fr 3fr 3fr;
    grid-template-areas: "first second second third"
    "fourth fourth fith fith";
    }
    @include mobile {
      grid-template-columns: 3fr 3fr;
    grid-template-areas: "first third "
    "second second"
    "fourth fourth"
    "fith fith";
    }
    grid-gap: 4rem;
    & > div {
      padding: 1rem !important;
      height: 400px;
      &:nth-of-type(1) {
       grid-area: first ;

      }

      &:nth-of-type(2) {
        grid-area: second;
      }
      &:nth-of-type(3) {
        grid-area: third;
      }
      &:nth-of-type(4) {
        grid-area: fourth;
      }
      &:nth-of-type(5) {
        grid-area: fith;

      }

    }
    .Image {
      max-height: 100%;
      .Image-Image {
        height: 100% !important ;
        object-fit: cover;
      }
    }
  }

  .Full-Width-Image {
    margin-top: 2rem;
    margin-bottom: 2rem;
    .Image {
      padding-bottom: 0;
      display: block;
      height: auto;
      .Image-Image {
        position: relative!important;
        height: auto!important;
        width: 100%!important;
      }
    }
  }

  .Case-List {
    background: $default-primary-dark-color;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 0 4rem 0;
    h2 {
      color: white;
      text-align: center;
      margin-bottom: 0;
    }
    .BlogPostCard {
      &-ImageWrapper {
        display: block;
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: relative;
        background: #07182c;

        .Image {
          width: 100%;
          height: 100%;
          display: block;
          transition: 0.3s ease-in-out;

          .Image-Image {
            display: block;
          }
        }
      }

      &-ContentWrapper {
        // min-height: 450px;
        transition: 0.2s ease-in-out;
        overflow: hidden;
        // &:hover {
        //   transform: scale(1.05);
        // }

        &:hover {
          .BlogPostCard-ImageWrapper .Image {
            transform: scale(1.05);
          }
        }
      }
    }
    .title {
      display: none;
    }
    .post-info {
      display: none;
    }
    .post-list-wrapper {
      padding: 0;
      margin: 0 auto;
    }
    li {
      padding-left: 0;
    }
    .post-list {
      @include desktop {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 2rem;
      }
      @include mobile {
        grid-template-columns: 1fr 1fr;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 750px;
        padding: 0 2rem;
      }
    }
    .post-holder {
      transition: 0.2s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      @include mobile {
        max-width: 45%;
      }
      @media only screen and (max-width: 400px) {
        max-width: 100%;
      }
    }
    .post-header {
      display: none;
    }
    .post-content {
      margin-top: 0;
      padding: 0;
      display: block;
      grid-template-columns: unset;
      grid-template-areas: unset;
      background: $white;
      @include desktop {
        min-height: 465px;
      }

      @include mobile {
        min-height: 485px;
        background: $white;
        padding-bottom: 2rem;
      }

      & > div {
        padding: 0;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        @include desktop {
          grid-template-rows: 200px 200px 45px;
        }

        & > div {
          padding: 0;
          margin-top: 0;
        }
      }
    }
    .post-text-hld {
      padding: 2rem !important;
      margin-top: 0;
      display: block;
      grid-template-columns: unset;
      grid-template-areas: unset;
      h4,
      h2,
      p {
        grid-area: unset;
        color: $default-primary-dark-color !important;
      }

      h4 {
        display: none;
      }
      h2 {
        font-size: 18px !important;
        text-align: left;
        margin: 0;
      }
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

      }
    }
    .post-description {
      .post-read-more {
        font-size: 12px;
        padding: 1rem 3rem;
        margin-left: 2rem;
        color: #07182c;
        border: 2px solid #07182c;
        transition: 0.2s ease-in-out;
        width: 150px;
        text-align: center;
        transition: 0.2s ease-in-out;
        &:hover {
          background: $default-primary-dark-color;
          color: $white;
        }
      }
    }
    .post-footer {
      display: none;
    }
  }
  .BlogRelatedPosts-PostsWrapper {
    grid-gap: 20px  ;
  }
  .BlogRelatedPosts-Title {
    display: none;
  }
  .BlogRelatedPosts-Wrapper,
  .BlogRelatedPosts-PostsWrapper,
  .BlogPostCard,
  .BlogPostCard-ContentWrapper,
  .BlogPostCard-Details,
  .BlogPostCard-Title,
  .BlogPostCard-DescriptionWrapper {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    color: #07182c;
    padding: 0;
    .Image {
      max-height: 220px;
      padding: 0;
    }
  }
  .BlogPostCard-Details {
    padding-bottom: 0;
    margin: 0!important;
  }
  .BlogPostCard-DescriptionWrapper {
    padding: 0 3rem;
    margin: 0 !important;
    overflow: hidden;
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .BlogPostCard {
    margin: 0!important;

    &-Title {
      padding: 16px 3rem 0 3rem;
      margin: 0!important;
      min-height: 0;
      font-size: 20px;
      text-decoration: none;
      font-family: "sofia pro black";
      color: var(--link-color);
    }
    &-Button {
      margin: 1rem 3rem;
    }
  }
  .BlogPostCard-ContentWrapper {
    background: #e6e6e6;
    margin: 0!important;
  }
}

.PostsListing-ColumnWrapper {
  flex-wrap: wrap-reverse;

  .PostsDetails {
    &-Sidebar {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
      div {
        flex-basis: 100%;
      }
      .BlogRecentPosts-Wrapper {
        background: $default-primary-dark-color !important;
        padding: 2rem 3rem !important;
        color: white !important;
        display: flex !important;
        flex-wrap: wrap;
        flex-basis: 100%;

        h3 {
          width: 100% !important;
          text-align: center !important;
          color: white;
        }
        .BlogRecentPosts-PostCard {
          display: flex;
          flex-wrap: wrap;
          color: white !important;
          border-bottom: 1px solid white;
          padding: 5rem 0;
          flex-basis: 33% !important;
          a {
            width: 100%;
          }
        }
        .BlogRecentPosts-ImageWrapper {
          flex-basis: 100% !important;
        }
      }
    }
    &-Description {
      flex-basis: 100%;
    }

    .BlogCategories-Wrapper {
      flex-basis: 100% !important;
    }
  }
}

.blog-header-image-container {
  max-width: none!important;
  margin-left: 0!important;
  margin-right: 0!important;
  margin-top: 0!important;
}

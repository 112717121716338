.Footer-Newsletter {
  background-image: url(images/Newsletter-Background.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr;
  padding: 75px 5rem;

  @include mobile {
    display: none;
  }
  .Newsletter {
    max-width: 1100px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 0.1fr 1fr;

    @include mobile {
      grid-template-columns: 1fr;
    }

    &-Title {
      h3 {
        font-size: 1.8rem;
        color: $default-base-orange-color;
        text-transform: uppercase;
        margin-top: 1.6rem;

        @include mobile {
          margin-left: 2rem;
        }
      }
    }
    &-Content {
      max-width: 730px;
      margin: 0 auto;
      text-align: center;
      @include desktop {
        margin: 0 auto 0 60px;
      }
      h3 {
        font-size: 50px;
        color: $white;
        margin-top: 0;

        @include mobile {
          max-width: 80%;
          margin: 0 auto;
          font-size: 3rem;
        }
      }
      p {
        color: white;
        font-size: 16px;
      }
    }
  }

  .Footer-Column_isNewsletter {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 2.5rem;

    .Footer-ColumnTitle {
      display: none;
    }
    .NewsletterSubscription {
      max-width: 550px;
      margin: 0 auto;

      @include mobile {
        width: 80%;
        padding: 2.5rem 0;
      }
      .FieldForm-Fieldset {
        display: grid;
        grid-template-columns: 10fr 1fr;
        grid-gap: 2rem;
      }
    }
    #newsletterEmail {
      font-size: 2rem;
      color: black;
      &::placeholder{
        color: black;
      }
    }
    .FieldForm-Button {
      padding: 0 3rem;
      background-image: url(images/mail-neg.svg);
      background-repeat: no-repeat;
      background-size:  25px;
      background-position: center;
      &::before, &::after{
        display: none;
      }
    }
  }
}

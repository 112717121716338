/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.BurgerMenu {
  &-Wrapper {
    height: 0;
    width: 0;
    overflow: visible;
  }

  &-Overlay {
    position: fixed;
    left: 0;
    top: 125px;
    background-color: white;
    padding: 0 50px 20px;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    opacity: 0;
    pointer-events: none;

    &_isVisible {
      opacity: 1;
      transition: opacity 200ms;
      pointer-events: all;
    }
  }

  &-Section {
    margin-right: auto;
  }

  &-SectionHeader {
    font-size: 11px;
    color: var(--button-background);
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &-SectionContent {
    list-style: none;
  }

  &-SectionContentItem {
    padding-left: 0;
    margin-bottom: 0;
    margin-left: 20px;

    &:before {
      content: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_level_1 {
      margin-left: 0;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &_level_2 {
      .BurgerMenu-ItemLink {
        font-size: 14px;
      }
    }

    &_level_3 {
      .BurgerMenu-ItemLink {
        font-size: 12px;
      }
    }
  }

  &-ItemLink {
    font-size: 16px;
    font-family: "open sans bold";
    color: black;
  }
}
